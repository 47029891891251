import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import  store  from './redux/store.js';
import { ThemeProvider, createTheme } from '@mui/material';
import { AuthProvider } from './hooks/useAppContext';
const theme = createTheme({
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
  },
  
  palette: {
    primary: {
      main: '#077DD3',
    },
    secondary: {
      main: '#F5F5F5',
    },
    error: {
      main: '#FF5454 ',
    },
    warning: {
      main: '#FFB800',
    },
    info: {
      main: '#2196f3',
    },
    success: {
      main: '#93B700',
    },
  },
  // components: {
  //   MuiButton: {
  //     styleOverrides: {
  //       root: {
  //         '&.small': {
  //           minWidth: '31.09px',
  //           minHeight: '31.09px',
  //         },
  //         '&.medium': {
  //           minWidth: '150px', 
  //           minHeight: '40px',
  //         },
  //         '&.large': {
  //           minWidth: '200px', 
  //           minHeight: '50px',
  //         },
  //       },
  //     },
  //   },
  // }
});
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  
    <AuthProvider>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider>
    </AuthProvider>
 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
