import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { useDispatch } from 'react-redux';


const setProfileData = (profileData) => ({
  type: 'profile/setProfileData',
  payload: profileData,
});
// const dispatch = useDispatch()
export const fetchReceptionistData = createAsyncThunk('fetchReceptionistData', async (_, { getState, dispatch })  => {
  try {
    

    // const  profileData  = getState().doctorSlice.profileData;
    const { profile } = getState().profileSlice;
    // console.log('ddd', profile);
    // console.log('docSlice profileData', profileData);
    const token = localStorage.getItem('token');
    // const userCategory = profileData.userCategory;
    // console.log('userCategory', userCategory);
    const headers = {
      Authorization: `Bearer ${token}`
    };
   let url = '';
  //  if(localStorage.getItem('userType').includes('user'))
  //  if(profile?.data?.userType.includes('user'))
  //  {
  //   if(profile?.data?.userCategory === 'Receptionist')
  //   {
  //     url = `${process.env.REACT_APP_BASE_URL}/users/getAssignedDoctores`;
  //   }
  //  }else{
url = `${process.env.REACT_APP_BASE_URL}/users/getAllReceptionist?count=50000`;
  //  }
    const res = await axios.get(
      url,
      {
        headers 
      }
    );

    // console.log(res.data, ' response data');
    return res.data;
  } catch (error) {
    console.log(error, ' error in userArticle');
  }
});

const receptionistSlice = createSlice({
  name: 'receptionistSlice',
  initialState: {
    isLoading: false,
    isSuccess: false,
    error: null,
    receptionist: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReceptionistData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchReceptionistData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // console.log(action.payload, 'API response'); // Log the entire response
        state.receptionist = action.payload;
        state.receptionist = action.payload?.data;
      })
      .addCase(fetchReceptionistData.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.error = action.payload;
      })
      
  }
});

export default receptionistSlice.reducer;
export const { resetFetchArticleListState } = receptionistSlice.actions;




