import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';


export const fetchDepartments = () => async (dispatch) => {
    dispatch(fetchDepartmentsStart());
    try {
      const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_URL}/department/findAllDepartment`,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      dispatch(fetchDepartmentsSuccess(response.data));
    } catch (error) {
      dispatch(fetchDepartmentsFailure(error.message));
    }
  };



const departmentsSlice = createSlice({
  name: 'departmentsSlice',
  initialState: {
    departments: [],
    loading: false,
    error: null,
  },
  reducers: {
    fetchDepartmentsStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchDepartmentsSuccess: (state, action) => {
      state.departments = action.payload;
      state.loading = false;
    },
    fetchDepartmentsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchDepartmentsStart, fetchDepartmentsSuccess, fetchDepartmentsFailure } = departmentsSlice.actions;



export default departmentsSlice.reducer;
