import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  units: [],
  loading: 'idle',
  error: null,
};

export const fetchUnits = createAsyncThunk(
  'testUnits/fetchUnits',
  async () => {
    try {
        const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found in localStorage');
      }
      
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(`${process.env.REACT_APP_PATHOLOGY_URL}/testUnits/findAllUnits`,{
        params: {
          count:100,
          pageNo:1,
        },
        headers,
      });
      return response.data?.data;
    } catch (error) {
      throw error; // Propagate the error up
    }
  }
);

const testUnitsSlice = createSlice({
  name: 'testUnitsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUnits.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchUnits.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.units = action.payload;
        state.error = null;
      })
      .addCase(fetchUnits.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message || 'Failed to fetch units';
      });
  },
});

export default testUnitsSlice.reducer;
