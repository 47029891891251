import React from 'react';

const EmployIcon = () => {
  return (
    <svg width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{height:'20px'}}>
      <path d="M14.0974 6.52138C15.7055 6.52138 17.0889 5.08553 17.0889 3.21573C17.0889 1.3685 15.6982 0 14.0974 0C12.497 0 11.106 1.39835 11.106 3.23065C11.106 5.08553 12.4894 6.52138 14.0974 6.52138ZM5.48927 6.69358C6.88034 6.69358 8.09229 5.43685 8.09229 3.81388C8.09229 2.20619 6.87269 1.01682 5.48927 1.01682C4.09857 1.01682 2.87169 2.23605 2.87934 3.82917C2.87934 5.43685 4.09092 6.69358 5.48964 6.69358M1.31643 13.4469H7.00011C6.22249 12.3175 7.17231 10.044 8.78036 8.80257C7.95031 8.2492 6.88034 7.83781 5.48199 7.83781C2.10899 7.83745 0 10.3276 0 12.3995C0 13.073 0.373888 13.4469 1.31643 13.4469ZM9.39343 13.4469H18.7941C19.9682 13.4469 20.3873 13.1101 20.3873 12.4519C20.3873 10.5224 17.9714 7.86002 14.0901 7.86002C10.2162 7.86002 7.80031 10.5224 7.80031 12.4523C7.80031 13.1101 8.21898 13.4469 9.39343 13.4469Z" fill="#2A2A2A"/>
    </svg>
  );
};

export default EmployIcon;
