import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import { useAuth } from '../../../hooks/useAppContext';
import { useNavigate } from 'react-router-dom';
import { Logout, Menu } from '@mui/icons-material';
import logo from '../../../assets/SVG/logo.png';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfileData } from '../../../redux/reducers/profileSlice';
import { fetchProjectProfileData } from '../../../redux/reducers/projectProfileSlice';
import socket from '../../../socket';
import { SocketResponse } from '../../../types';
interface HeaderProps {
  drawerToggler: () => void;
}
const Header: React.FC<HeaderProps> = ({ drawerToggler }) => {
  const { logout, isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const profile = useSelector((state: any) => state.profileSlice.profile)
  const loading = useSelector((state: any) => state.profileSlice.loading);
  const projectProfile = useSelector((state: any) => state.projectProfileSlice.projectProfile)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchProfileData() as any)
    dispatch(fetchProjectProfileData() as any);
  }, [dispatch])
  useEffect(()=>{
    localStorage.setItem('projectOP',profile?.data?.projectId);
  },[loading])
  
  useEffect(() => {
    if (!profile?.data?.projectId) return;

    const projectId = profile.data.projectId.toString();

    const handleProfileUpdate = (data: SocketResponse) => {
     
      if (data.module === 'profile' && data.operation === 'update') {
        dispatch(fetchProfileData() as any);
        dispatch(fetchProjectProfileData() as any);
      }
    };

   
    socket.on(projectId, handleProfileUpdate);

    // Cleanup on unmount or when project ID changes
    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off(projectId, handleProfileUpdate);
    };
  }, [profile?.data?.projectId, dispatch]);
  const [imageError, setImageError] = useState<boolean>(false);

  const handleImageError = () => {
    setImageError(true);
  };
  const handleLogout = () => {
    if (isLoggedIn) {
      logout();
      navigate('/');
    }
  };
  return (

    <Box sx={{ flexGrow: 1 }}>
      <AppBar position='static'>
        <Toolbar>
          <IconButton
            size='large'
            edge='start'
            color='inherit'
            aria-label='open drawer'
            sx={{ mr: 2 }}
            onClick={() => drawerToggler()}
          >
            <Menu />
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              margin: 'auto',
            }}
          >
            <Typography
              variant='h6'
              noWrap
              component='div'
              sx={{ flexGrow: 1, display: { xs: 'none', sm: 'none', md:'block' } }}
            >

              {projectProfile?.hospitalLogoUrl ? <img src={projectProfile?.hospitalLogoUrl} alt={projectProfile?.hospitalName + ' logo'} style={{ height: '40px', cursor: 'pointer' }} onClick={() => navigate('/')} onError={handleImageError} /> : <img src={logo} style={{ height: '40px', cursor: 'pointer' }} onClick={() => navigate('/')} />}

            </Typography>
            <Typography
              variant='h6'
              noWrap
              component='div'
              sx={{ flexGrow: 1, display: { xs: 'none', lg: 'block', sm:'none' }, textTransform: 'uppercase' }}
            >
              {projectProfile?.hospitalName}
            </Typography>
            <Typography variant='h6' sx={{ fontSize: { lg: '1.25rem', md: '1rem', xs: '0.8rem' }, textTransform: 'capitalize' }}>
              {loading ? '' : profile?.data?.userType.includes('admin') ? "Welcome, " + profile?.data?.firstName + ' ' + profile?.data?.lastName : "Welcome, " + profile?.data?.fullName}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
              {/* <PrimarySearchBar /> */}
              {isLoggedIn && (
                // <Button
                //   onClick={handleLogout}
                //   startIcon={<Logout />}
                //   sx={{
                //     background: 'white',
                //     fontWeight: '500',
                //     py: '6px',
                //     marginLeft: '20px',
                //     ':hover': {
                //       bgcolor: 'gray',
                //       color: 'white',
                //     },
                //   }}
                //   variant='outlined'
                // >
                //   Logout
                // </Button>
                <button className="Btn" onClick={handleLogout}>
                  <div className="sign">
                    <svg viewBox="0 0 512 512">
                      <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
                    </svg>
                  </div>
                  <div className="text">Logout</div>
                </button>
              )}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
export default Header;
