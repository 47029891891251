// reportsDocslice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchReportById = createAsyncThunk(
  'reportsDocslice/fetchReportById',
  async function (id) {
    try {
      const token = localStorage.getItem('token');
      const headers = {
        Authorization: `Bearer ${token}`
      };
      let url = `${process.env.REACT_APP_BASE_URL}/users/getAllDoctors`;
      if(id)
      {
        url+= `?receptionistId=${id}`;
      }
      const response = await axios.get(url, { headers });
      
      return response.data?.data;
    
    } catch (error) {
      console.log('Error in fetchReportById:', error);
      throw error;
    }
  }
);

const reportsDocslice = createSlice({
  name: 'reportsDocslice',
  initialState: {
    isLoading: false,
    isSuccess: false,
    error: null,
    doctor: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReportById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchReportById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.doctor = action.payload;
      })
      .addCase(fetchReportById.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.error = action.payload;
      });
  }
});

export default reportsDocslice.reducer;
export const { resetFetchArticleListState } = reportsDocslice.actions;
