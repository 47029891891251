import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
// Define the initial state
const initialState = {
  symptoms: [],
  loading: false,
  error: null,
}; 
// Define the async thunk to fetch symptoms from the API
export const fetchSymptoms = createAsyncThunk(
  'symptoms/fetchSymptoms',
  async (searchTerm : string) => {
    try {
      // const count = 3;
      const response = await axios.get(
        `${process.env.REACT_APP_OPD_API_URL}/symptoms/getAll?count=50000&search=${encodeURIComponent(searchTerm)}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      return response.data?.data; // Assuming API returns an array of symptoms
    } catch (error : any) {
      return error.response.data;
    }
  }
);
// Create the slice
const symptomsSlice = createSlice({
  name: 'symptomsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSymptoms.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSymptoms.fulfilled, (state, action) => {
        state.loading = false;
        state.symptoms = action.payload; // Assuming response has a 'data' property
      })
      .addCase(fetchSymptoms.rejected, (state : any,  action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default symptomsSlice.reducer;
// Selectors
export const selectSymptoms = (state : any) => state.symptomsSlice?.symptoms;
export const selectLoading = (state : any) => state.symptomsSlice?.loading;
export const selectError = (state : any) => state.symptomsSlice?.error;