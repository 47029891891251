import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk to fetch user categories
export const fetchUserCategories = createAsyncThunk(
  'userCategories/fetchUserCategories',
  async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_URL}/user-category/getAllCategories?count=100&pageNo=1`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      // You can handle errors here
      throw error;
    }
  }
);
  
// Define the initial state
const initialState = {
  categories: [],
  loading: false,
  error: null,
  isSuccess:false
};

// Create the user categories slice
const userCategoriesSlice = createSlice({
  name: 'userCategoriesSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserCategories.fulfilled, (state, action) => {
        //   console.log('action.payload', action.payload.data);
        state.loading = false;
        state.categories = action.payload.data;
        state.isSuccess = true;
      })
      .addCase(fetchUserCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default userCategoriesSlice.reducer;

// Selectors
export const selectUserCategories = (state) => state.userCategoriesSlice.categories;
export const selectUserCategoriesLoading = (state) => state.userCategoriesSlice.loading;
export const selectUserCategoriesError = (state) => state.userCategoriesSlice.error;
export const selectUserCategoriesSuccess = (state) => state.userCategoriesSlice.isSuccess;
