import { CircularProgress } from '@mui/material';
import React from 'react';

const Preloader: React.FC = () => {
  const preloaderStyle: React.CSSProperties = {
    position: 'fixed',
    zIndex: 9999,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.7)', // Semi-transparent white background
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const spinnerStyle: React.CSSProperties = {
    color: '#1976D2', // Material-UI primary color
    marginRight: 10,
  };

  return (
    <div style={preloaderStyle}>
      <CircularProgress style={spinnerStyle} />
     
    </div>
  );
};

export default Preloader;
