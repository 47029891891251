import React from "react";
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";

interface AutocompleteProps {
  options: any[];
  value?: any;
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    newValue: any[],
    reason?: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any>
  ) => void;
  isOptionEqualToValue?: (option: any, value: any) => boolean;
  getOptionLabel?: (option: any) => string;
  renderOption?: (
    props: React.HTMLProps<HTMLLIElement>,
    option: any,
    state: { selected: boolean }
  ) => React.ReactElement;
  handleAddClick?: () => void;
  placeholder?: string;
  multiple?: boolean;
  disabled?: boolean;
  required?: boolean;
  helperText?: any;
  error?: any;
  onKeyPress?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  filterOptions?: (options: any[], state: { inputValue: string }) => any[];
  onTextFieldChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void; // Updated type
}

const CustomAutocomplete: React.FC<AutocompleteProps> = ({
  options,
  value,
  onChange,
  isOptionEqualToValue,
  getOptionLabel,
  renderOption,
  handleAddClick,
  placeholder = "Select...",
  multiple = false,
  disabled = false,
  required = false,
  error,
  helperText,
  onKeyPress,
  filterOptions,
  onTextFieldChange,
  handleBlur,
  ...rest
}) => {
  return (
    <Autocomplete
      multiple={multiple}
      fullWidth
      size="small"
      options={options || []}
      value={value || []}
      // onChange={onChange}
      onChange={(event, newValue, reason, details) => {
        if (reason === "removeOption") {
          // Do nothing to prevent removing selected items on backspace
          return;
        }
        onChange(event, newValue, reason, details);
      }}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      filterOptions={filterOptions}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          variant="outlined"
          fullWidth
          size="small"
          required={required}
          disabled={disabled}
          className="search-field"
          helperText={helperText}
          error={error}
          onBlur={handleBlur}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {params.InputProps.endAdornment}
                {handleAddClick && (
                  <AddIcon
                    onClick={handleAddClick}
                    sx={{
                      background: "#FFFFFF",
                      color: "#055FFC",
                      borderRadius: "50%",
                      p: 0.2,
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                  />
                )}
              </React.Fragment>
            ),
          }}
          onChange={onTextFieldChange}
          onKeyPress={onKeyPress}
          {...rest}
        />
      )}
      renderTags={() => null}
    />
  );
};

export default CustomAutocomplete;
