import { Box, Button, Divider, Grid, IconButton, List, ListItem, ListItemText, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { wrap } from 'module';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DeleteConfirmationModal from '../../../../components/Modals/delete-modal';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import parse from 'html-react-parser';


const Instruction = () => {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [instructions, setInstructions] = useState<any>([]);
    const [selectedInstruction, setSelectedInstruction] = useState<any>(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState<any>("");

    useEffect(() => {
        fetchInstructions(searchTerm);
    }, [searchTerm]);

    const fetchInstructions = async (search = '') => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_OPD_API_URL}/set-of-instructions/getAllSetOfInstructionsBySearch?search=${search}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`  // Add your token here if required
                } // Pass token here if required
                // Pass search params here
            });
            if (response.data.isSuccess) {
                setInstructions(response?.data?.data);
            } else {
                console.error(response?.data?.message);
            }
        } catch (error) {
            console.error('Error fetching instructions:', error);
        }
    };

    const handleSearchChange = (e: any) => {
        const value = e.target.value;
        setSearchTerm(value);
        fetchInstructions(value); // Fetch with updated search term
    };


    const handleInstructionClick = (instruction: any) => {
        setSelectedInstruction(instruction);
    };

    const handleEdit = () => {
        if (selectedInstruction) {
            // Navigate to the edit page, passing the selected instruction's ID
            navigate('/instruction-editor', { state: { id: selectedInstruction._id } });
        }
    };

    const handleDeleteModalClose = () => {
        setIsDeleteModalOpen(false);
    };

    const handleDeleteClick = (id: any) => {
        setIsDeleteModalOpen(true);
        setSelectedItemId(id);
    };

    const handleDeleteConfirm = async () => {
        try {
            const token = localStorage.getItem("token");
            if (!token) {
                console.error("Authorization token is missing");
                return;
            }

            const response = await axios.delete(
                `${process.env.REACT_APP_OPD_API_URL}/set-of-instructions/deleteSetOfInstructionsById/${selectedItemId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            toast.success(response?.data?.message);
            fetchInstructions()
        } catch (error: any) {
            toast.error(error?.message);
        }
        setIsDeleteModalOpen(false); // Close the modal after deletion
    };

    return (
        <Box>
            <Box sx={{
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'space-between',
                background: '#fff',
                borderRadius: '12px',
                p: 1
            }}>
                <Typography variant='h4' sx={{ fontSize: '18px', fontWeight: '600' }}>Instructions</Typography>
                <Button size='small' variant='contained' onClick={() => navigate('/instruction-editor')}>+ Instructions</Button>
            </Box>

            <Box sx={{ height: '78vh', px: 1, pt: 1, background: '#fff', borderRadius: '12px', mt: 1 }}>
                <Grid container spacing={2}>
                    {/* Sidebar */}
                    <Grid item xs={3}>
                        <Box
                            sx={{
                                borderRight: '1px solid #ccc',
                                px: 0.5,
                                height: '74vh',
                                overflowY: 'auto',
                                '&::-webkit-scrollbar': {
                                    width: '4px',
                                },
                                '&::-webkit-scrollbar-track': {
                                    background: '#f1f1f1',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    background: '#055FFC',
                                    borderRadius: '10px',
                                },
                                '&::-webkit-scrollbar-thumb:hover': {
                                    background: '#555',
                                },
                            }}
                        >
                            <TextField
                                variant="outlined"
                                fullWidth
                                placeholder="Search instructions..."
                                value={searchTerm}
                                onChange={handleSearchChange}
                                size='small'
                                className='search-field'
                                sx={{
                                    position: 'sticky',
                                    top: 0,
                                    // background: '#fff', // Ensure background is white for visibility
                                    zIndex: 1, // Ensure it stays above the list items
                                    mb: 1 // Margin bottom to space it from the list
                                }}
                            />
                            <List>
                                {instructions?.map((instruction: any) => (
                                    <React.Fragment key={instruction?._id}>
                                        <ListItem
                                            button
                                            onClick={() => handleInstructionClick(instruction)}
                                        >
                                            <ListItemText
                                                primary={instruction?.setName}
                                                secondary={
                                                    <Typography variant="body2" color="textSecondary" noWrap>
                                                        {instruction?.instructions?.replace(/<[^>]+>/g, '').substring(0, 40)}...
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                        <Divider />
                                    </React.Fragment>
                                ))}
                            </List>
                        </Box>
                    </Grid>

                    {/* Main Body */}
                    <Grid item xs={9}>
                        <Box
                            sx={{
                                height: '70vh',
                                overflowY: 'auto',
                                '&::-webkit-scrollbar': {
                                    width: '4px',
                                },
                                '&::-webkit-scrollbar-track': {
                                    background: '#f1f1f1',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    background: '#055FFC',
                                    borderRadius: '10px',
                                },
                                '&::-webkit-scrollbar-thumb:hover': {
                                    background: '#555',
                                },
                            }}
                        >
                            <Box sx={{ padding: 2, position: 'relative' }}>
                                {selectedInstruction ? (
                                    <>
                                        <Box sx={{
                                            display: 'flex', justifyContent: 'space-between', mb: 2,
                                            direction: selectedInstruction?.exta?.language === 'ur' ? 'rtl' : 'ltr',
                                            textAlign: selectedInstruction?.exta?.language === 'ur' ? 'right' : 'left'
                                        }}>
                                            <Typography variant="h4">{selectedInstruction?.setName}</Typography>
                                            <Box>
                                                <IconButton onClick={handleEdit}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton onClick={() => handleDeleteClick(selectedInstruction?._id)} color="error">
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                        <Box component="div" sx={{
                                            typography: 'body1',
                                            direction: selectedInstruction?.exta?.language === 'ur' ? 'rtl' : 'ltr',
                                            textAlign: selectedInstruction?.exta?.language === 'ur' ? 'right' : 'left'
                                        }} dangerouslySetInnerHTML={{ __html: selectedInstruction.instructions }} />
                                    </>
                                ) : (
                                    <Typography variant="body1">Select an instruction to view details.</Typography>
                                )}
                            </Box>

                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <DeleteConfirmationModal
                open={isDeleteModalOpen}
                handleClose={handleDeleteModalClose}
                handleConfirm={handleDeleteConfirm}
            />
        </Box>
    );
};

export default Instruction;
