import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const initialState = {
    profile: null,
    loading: false,
    error: null,
  };
// Define an asynchronous thunk action to fetch projectProfile data from the API
export const fetchProjectProfileData = createAsyncThunk(
    'projectProfile/fetchProjectProfileData',
    async (_, { rejectWithValue }) => { // Use destructuring to access `rejectWithValue` function
      try {
        const token = localStorage.getItem('token')
        if (!token) {
          throw new Error('Token not found');
        }
        
        const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_URL}/projects/getProjectProfile`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        
        return response?.data?.data;
      } catch (error) {
        return rejectWithValue(error.message); // Use `rejectWithValue` to pass error message to reducer
      }
    }
  );
  
  // Create a slice for the projectProfile data
  const projectProfileSlice = createSlice({
    name: 'projectProfileSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchProjectProfileData.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(fetchProjectProfileData.fulfilled, (state, action) => {
          state.loading = false;
          state.projectProfile = action.payload; // Update projectProfile instead of profile
        })
        .addCase(fetchProjectProfileData.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload; // Access error message from action.payload
        });
    },
  });
  
  // Export actions and reducer
  export default projectProfileSlice.reducer;
  