import { BrowserRouter} from 'react-router-dom';
import RoutesHOC from './routes/RoutesHOC';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useTokenExpirationCheck from './hooks/useTokenExpirationCheck';
const App=()=>{
 useTokenExpirationCheck();
  return (
    <div>
    <BrowserRouter>
      <RoutesHOC/>
      <ToastContainer/>
    </BrowserRouter>
    </div>
  );
}

export default App;
