import React, { useEffect, useState } from "react";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.css";
import {
  ArrowRight,
  Close,
  GridView,
  PersonAdd,
  SettingsSuggest,
} from "@mui/icons-material";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import InsuranceIcon from "../../../assets/SVG/insuranceIcon";
import PathologyIcon from "../../../assets/SVG/pathology";
import ReportIcon from "../../../assets/SVG/ReportIcon";
import AppointmentIcon from "../../../assets/SVG/AppointmentIcon";
import EmployIcon from "../../../assets/SVG/EmployIcon";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AccessConfigIcon from "../../../assets/SVG/AccessConfigIcon";
import {  useSelector } from "react-redux";
import { fetchProfileData } from "../../../redux/reducers/profileSlice";
import { Roles } from "../../../enums";
import { title } from "process";
import OPDIcon from "../../../assets/SVG/OPDIcon";
import { fetchProjectProfileData } from "../../../redux/reducers/projectProfileSlice";
import IPDicon from "../../../assets/SVG/IPDicon";
// import AddNewTest from '../../pathology/add-new-test';
interface SideBarProps {
  isOpen: boolean;
  closeDrawer: () => void;
}
const SideBar: React.FC<SideBarProps> = ({ isOpen, closeDrawer }) => {
  const isSmallScreen = useMediaQuery(" (max-width:960px)");
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const navigate = useNavigate(); // Initialize useNavigate
  const [activeSubMenu, setActiveSubMenu] = useState<string>(""); // Change activeSubMenu to string
  const [subIndex, setSubIndex] = useState<number | null>(null);
  const [activeInnerMenu, setActiveInnerMenu] = useState<string>("");
  const location = useLocation();
  const profile = useSelector((state: any) => state.profileSlice.profile);
  const category = profile?.data?.userType;
  const userAccess = profile?.data?.roles;
  const profileLoading = useSelector(
    (state: any) => state.profileSlice.loading
  );
  const projectProfile = useSelector(
    (state: any) => state.projectProfileSlice.projectProfile
  );

  const accessConfigurations = profile?.data?.accessConfigurations;
 
  let options: any = [];

  let pathology = {
    title: "Pathology",
    icon: <PathologyIcon />,
    // patch: '/pathology',
    subMenu: [
      {
        title: "Registration",
        route: "/patient-registration-and-test-entry",
      },
      {
        title: "Test Results",
        route: "/test-result",
      },
      {
        title: "Admin",
        route: "/pathology",
      },
      {
        title: "Analysis",
        route: "/pathology-analysis",
      },
      {
        title: "Stock",
        route: "/stock-management",
      },
    ],
    priority: 105,
  };

  let opd = {
    title: "OPD",
    icon: <OPDIcon />,
    // patch: '',
    subMenu: [
      {
        title: "Dashboard",
        route: "/opd-dashboard",
      },
      {
        title: "Settings",
        route: "/opd-admin",
        innerSubMenu: [
          {
            title: "Medicine",
            route: "/add-new-medicine",
          },
          {
            title: "Provisional Diagnosis/DD",
            route: "/diagnosis",
          },
          {
            title: "Investigations",
            route: "/procedure",
          },
          {
            title: "History , Examination & Procedure",
            route: "/symptoms",
          },
          {
            title: "Case",
            route: "/case-dashboard",
          },
          {
            title: "Set of instructions",
            route: "/set-instructions",
          },
        ],
      },
    ],
    priority: 120,
  };

  let ipd = {
    title: "IPD",
    icon: <IPDicon />,
    // patch: '',
    subMenu: [
      {
        title: "Dashboard",
        route: "/ipd-dashboard",
      },
      {
        title: "Setting",
        // route: '/infrastructure',
        innerSubMenu: [
          {
            title: "Infrastructure",
            route: "/infrastructure",
          },
          // {
          //   title: "Departments",
          //   route: "/departments",
          // },
          {
            title: "Services",
            route: "/services",
          },
          // {
          //   title: "Dynamic Form",
          //   route: "/dynamic-form",
          // },
        ],
      },
    ],
    priority: 135,
  };

  // options.push(ipd);

  projectProfile?.departmentsAccess &&
    projectProfile?.departmentsAccess?.forEach((department: string) => {
      switch (department) {
        case "opd":
          options.push(opd);
          break;
        case "pathology":
          options.push(pathology);
          break;
        case "ipd":
          options.push(ipd);
          break;
        default:
          break;
      }
    });
  accessConfigurations &&
    Object.keys(accessConfigurations).map((key) => {
      switch (key) {
        // case 'registration':
        //   if(accessConfigurations[key]?.edit_patient ){

        //     options.push(  {
        //       title: 'Patients',
        //       icon: <PersonAdd sx={{color:'#000000',height:'20px',minHeight:'20px'}}/>,
        //       patch: '/update-patient',

        //      priority:15
        //     })
        //   }
        //     break;
        case "appointment":
          if (accessConfigurations[key]?.view_module) {
            let data = {
              title: "Appointments",
              icon: <AppointmentIcon />,
              patch: "/appointment-dashboard",
              subMenu: [
                {
                  title: "Reports",
                  route: "/user-reports",
                },
                {
                  title: "Counter",
                  route: "/counter",
                },
                {
                  title: "Delete History",
                  route: "/delete-history",
                },
              ],
              priority: 0,
            };
            //  if(!accessConfigurations[key]?.add_appointment)
            //  {
            //    let index =  data?.subMenu?.findIndex((item:any) => item.route === '/patient-registration');
            //    data?.subMenu?.splice(index,1);
            //  }
            if (projectProfile?.departmentsAccess.includes("registration")) {
              options.push(data);
            }
          }
          break;

        case "employee":
          if (accessConfigurations[key]?.view_module) {
            let data = {
              title: "Employee",
              icon: <EmployIcon />,
              patch: "/employe",
              subMenu: [
                {
                  title: "Category",
                  route: "/employe-category",
                },
                {
                  title: "Add Employe",
                  route: "/employe-add",
                },
                {
                  title: "Departments",
                  route: "/add-department",
                },
              ],
              priority: 30,
            };
            if (!accessConfigurations[key]?.view_category) {
              let index = data?.subMenu?.findIndex(
                (item: any) => item.route === "/employe-category"
              );
              data?.subMenu?.splice(index, 1);
            }
            if (!accessConfigurations[key]?.view_department) {
              let index = data?.subMenu?.findIndex(
                (item: any) => item.route === "/add-department"
              );
              data?.subMenu?.splice(index, 1);
            }
            if (!accessConfigurations[key]?.add_employee) {
              let index = data?.subMenu?.findIndex(
                (item: any) => item.route === "/employe-add"
              );
              data?.subMenu?.splice(index, 1);
            }
            if (projectProfile?.departmentsAccess?.includes("registration")) {
              options.push(data);
            }
          }
          break;
        case "insurance":
          if (accessConfigurations[key]?.view_module) {
            let data = {
              title: "Insurance",
              icon: <InsuranceIcon />,
              patch: "/insurances",
              subMenu: [
                {
                  title: "Companies",
                  route: "/companies",
                },
                {
                  title: "Add Services",
                  route: "/insurance-services",
                },
                {
                  title: "Reports",
                  route: "/insurance-reports",
                },
              ],
              priority: 45,
            };
            if (!accessConfigurations[key]?.view_companies) {
              let index = data?.subMenu?.findIndex(
                (item: any) => item.route === "/companies"
              );
              data?.subMenu?.splice(index, 1);
            }
            if (!accessConfigurations[key]?.view_services) {
              let index = data?.subMenu?.findIndex(
                (item: any) => item.route === "/insurance-services"
              );
              data?.subMenu?.splice(index, 1);
            }
            if (!accessConfigurations[key]?.view_reports) {
              let index = data?.subMenu?.findIndex(
                (item: any) => item.route === "/insurance-reports"
              );
              data?.subMenu?.splice(index, 1);
            }
            if (projectProfile?.departmentsAccess.includes("registration")) {
              options.push(data);
            }
          }
          break;
        case "reports":
          if (
            !accessConfigurations[key]?.view_module &&
            options.some((obj: any) => obj.title === "Appointments")
          ) {
            let getSubMenu = options.find(
              (item: any) => item?.title === "Appointments"
            )["subMenu"];
            let findIndex = getSubMenu.findIndex(
              (item: any) => item.title === "Reports"
            );
            options
              .find((item: any) => item?.title === "Appointments")
            ["subMenu"].splice(findIndex, 1);
          }
          break;
        case "counter":
          if (
            !accessConfigurations[key]?.view_module &&
            options.some((obj: any) => obj.title === "Appointments")
          ) {
            let getSubMenu = options.find(
              (item: any) => item?.title === "Appointments"
            )["subMenu"];
            let findIndex = getSubMenu.findIndex(
              (item: any) => item.title === "Counter"
            );
            options
              .find((item: any) => item?.title === "Appointments")
            ["subMenu"].splice(findIndex, 1);
          }
          break;
        case "deleteHistory":
          if (
            !accessConfigurations[key]?.view_module &&
            options.some((obj: any) => obj.title === "Appointments")
          ) {
            let getSubMenu = options.find(
              (item: any) => item?.title === "Appointments"
            )["subMenu"];
            let findIndex = getSubMenu.findIndex(
              (item: any) => item.title === "Delete History"
            );
            options
              .find((item: any) => item?.title === "Appointments")
            ["subMenu"].splice(findIndex, 1);
          }
          break;

        default:
          // setRestrictPrivatePaths(PrivatePaths);
          break;
      }
    });
  if (category?.includes("superAdmin")) {
    options.push({
      title: "Hospitals",
      icon: <AdminPanelSettingsIcon />,
      patch: "/super-admin-dashboard",
      subMenu: [
        {
          title: "Admins",
          route: "/all-admins",
        },
      ],
      priority: 75,
    });
  }
  if (category?.includes("admin")) {
    options.push({
      title: "Settings",
      icon: <AccessConfigIcon />,
      patch: "/access-config",
      priority: 90,
      subMenu: [
        {
          title: "Access Config",
          route: "/access-config",
        },
        {
          title: "Default Config",
          route: "/default-config",
        },
        {
          title: "Default Header&Footer",
          route: "/default-header-footer",
        },

      ],
    });

    // options = options.filter((option:any) => option.title !== 'OPD');
    // options.push({
    //   title: 'OPD',
    //   icon: <OPDIcon />,
    //   // patch: '',
    //   subMenu: [
    //     {
    //       title:'Admin',
    //       route:'/opd-admin',
    //       innerSubMenu:[
    //         {
    //           title: 'Medicine',
    //           route: '/add-new-medicine'
    //         },
    //         {
    //           title: 'Diagnostic',
    //           route: '/diagnosis'
    //         },
    //         {
    //           title: 'Procedure',
    //           route: '/procedure'
    //         },
    //         {
    //           title: 'Symptoms & Signs',
    //           route: '/symptoms'
    //         },
    //         {
    //           title: 'Case',
    //           route: '/case-dashboard'
    //         }
    //       ]
    //     }
    //    ]}

    //   );
  }
  const handleItemClick = (index: number) => {
    setActiveSubMenu("");
    setActiveInnerMenu("");
    setSubIndex(null);
    if (options[index].title === "Appointments") {
      setActiveIndex(activeIndex === index ? null : index); // Toggle active index for accordion
      navigate("/appointment-dashboard"); // Navigate to '/' route
    } else if (options[index].title === "Counter") {
      setActiveIndex(activeIndex === index ? null : index); // Toggle active index for accordion
      navigate("/counter"); // Navigate to '/' route
    }
    // else if(options[index].title === 'Patients')
    // {
    //   setActiveIndex(activeIndex === index ? null : index); // Toggle active index for accordion
    //   navigate('/update-patient'); // Navigate to '/' route
    // }
    else if (options[index].title === "Insurance") {
      setActiveIndex(activeIndex === index ? null : index); // Toggle active index for accordion
      navigate("/insurances"); // Navigate to '/' route
    } else if (options[index].title === "Reports") {
      setActiveIndex(activeIndex === index ? null : index); // Toggle active index for accordion
      navigate("/user-reports"); // Navigate to '/' route
    } else if (options[index].title === "Employee") {
      setActiveIndex(activeIndex === index ? null : index); // Toggle active index for accordion
      navigate("/employe"); // Navigate to '/' route
    } else if (options[index].title === "Pathology") {
      setActiveIndex(activeIndex === index ? null : index); // Toggle active index for accordion
      navigate("/pathology"); // Navigate to '/' route
    } else if (options[index].title === "OPD") {
      setActiveIndex(activeIndex === index ? null : index); // Toggle active index for accordion
      navigate("/opd-dashboard"); // Navigate to '/' route
    } else if (options[index].title === "IPD") {
      setActiveIndex(activeIndex === index ? null : index); // Toggle active index for accordion
      navigate("/ipd-dashboard"); // Navigate to '/' route
    } else if (options[index].title === "Settings") {
      setActiveIndex(activeIndex === index ? null : index); // Toggle active index for accordion
      navigate("/access-config"); // Navigate to '/' route
    }

    //   else if(options[index].title === 'Access Config')
    // {
    //   setActiveIndex(activeIndex === index ? null : index); // Toggle active index for accordion
    //   navigate('/access-config'); // Navigate to '/' route
    // }
    else if (options[index].title === "Settings") {
      setActiveIndex(activeIndex === index ? null : index); // Toggle active index for accordion
      navigate("/access-config"); // Navigate to '/' route
    } else if (options[index].title === "Hospitals") {
      setActiveIndex(activeIndex === index ? null : index); // Toggle active index for accordion
      navigate("/super-admin-dashboard");
    } else {
      setActiveIndex(activeIndex === index ? null : index);
    }
  };
  options.sort((a: any, b: any) => a.priority - b.priority);
  const handleSubMenuClick = (route: string, subMenuIndex: number) => {
    setActiveInnerMenu("");
    if (subMenuIndex !== null) {
      setSubIndex(subMenuIndex);
    }
    navigate(route);
    setActiveSubMenu(route); // Update activeSubMenu state with the route
  };
  const handleInnerSubMenuClick = (route: string) => {
    // if(subMenuIndex!==null)
    //   {
    //     setSubIndex(subMenuIndex);
    //   }
    navigate(route);
    setActiveInnerMenu(route);
    // setActiveSubMenu(route); // Update activeSubMenu state with the route
  };
  useEffect(() => {
    options &&
      options.map((item: any, index: any) => {
        if (item.patch === location.pathname) {
          setActiveIndex(index);
        } else if (item?.subMenu) {
          item.subMenu.map((item2: any, index2: number) => {
            if (item2?.route === location.pathname) {
              setActiveIndex(index);
              setActiveSubMenu(item2.route);
            } else {
              item2?.innerSubMenu?.map((item3: any, index3: any) => {
                if (item3?.route === location.pathname) {
                  setActiveIndex(index);
                  setActiveSubMenu(item2.route);
                  setSubIndex(index2);
                  setActiveInnerMenu(item3?.route);
                }
              });
            }
          });
        }
      });
  }, [profileLoading, location.pathname]);
  return (
    <Box
    //  sx={{
    //   width: isSmallScreen ? "0%" : 340,
    //   height: '90vh',
    //   overflowY: 'auto',
    //   '&::-webkit-scrollbar': {
    //     width: '4px',
    //   },
    //   '&::-webkit-scrollbar-track': {
    //     background: '#f1f1f1',
    //   },
    //   '&::-webkit-scrollbar-thumb': {
    //     background: '#055FFC',
    //     borderRadius: '10px',
    //   },
    //   '&::-webkit-scrollbar-thumb:hover': {
    //     background: '#555',
    //   },
    // }}
    >
      {isSmallScreen ? (
        <Drawer
          anchor="left"
          open={isOpen}
          sx={{
            width: isSmallScreen ? "80%" : 340,
          }}
          onClick={closeDrawer}
        >
          <div>
            <IconButton onClick={closeDrawer} sx={{ alignSelf: "flex-end" }}>
              <Close />
            </IconButton>
          </div>
          <List>
            {options.map((option: any, index: any) => (
              <React.Fragment key={index}>
                <ListItem
                  key={option.title}
                  disablePadding
                  sx={{
                    background:
                      activeIndex === index ? "#f9fbff" : "transparent",
                    borderLeft:
                      activeIndex === index
                        ? "4px solid #077DD3"
                        : "transparent",
                  }}
                >
                  <ListItemButton onClick={() => handleItemClick(index)}>
                    <ListItemIcon
                      sx={{
                        filter:
                          activeIndex === index
                            ? "invert(24%) sepia(98%) saturate(6571%) hue-rotate(183deg) brightness(93%) contrast(92%)"
                            : "invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)",
                      }}
                    >
                      {option.icon}
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        filter:
                          activeIndex === index
                            ? "invert(24%) sepia(98%) saturate(6571%) hue-rotate(183deg) brightness(93%) contrast(92%)"
                            : "invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)",
                        "& .MuiTypography-root": {
                          fontSize: {
                            md: "0.8em",
                            xs: "0.6em",
                            xl: "0.8em",
                            sm: "0.6em",
                            lg: "0.8em",
                          },
                        },
                      }}
                    >
                      {option.title}
                    </ListItemText>
                    {activeIndex === index ? (
                      <ArrowRight color="primary" />
                    ) : (
                      ""
                    )}
                  </ListItemButton>
                </ListItem>
                {activeIndex === index && (
                  <List sx={{ pl: isSmallScreen ? "0" : "80px" }}>
                    {option.subMenu?.map((subOption: any, subIndex1: any) => (
                      <>
                        <ListItem
                          className={
                            activeSubMenu === subOption.route ? "active" : ""
                          }
                          key={subIndex1}
                          sx={{
                            color:
                              activeSubMenu === subOption.route
                                ? "#077DD3"
                                : "#3F3F3F", // Check if activeSubMenu matches route
                            cursor: "pointer",
                            background:
                              activeSubMenu === subOption.route
                                ? "#f9fbff"
                                : "transparent",
                            borderLeft:
                              activeSubMenu === subOption.route
                                ? "4px solid #077DD3"
                                : "transparent",
                          }}
                          onClick={() =>
                            handleSubMenuClick(subOption.route, subIndex1)
                          } // Handle sub menu item click
                        >
                          {activeSubMenu === subOption.route ? (
                            <ArrowRight />
                          ) : (
                            <ArrowRight />
                          )}{" "}
                          <ListItemText
                            primary={subOption.title}
                            sx={{
                              "& .MuiTypography-root": {
                                fontSize: {
                                  md: "0.8em",
                                  xs: "0.6em",
                                  xl: "0.8em",
                                  sm: "0.6em",
                                  lg: "0.8em",
                                },
                              },
                            }}
                          />
                        </ListItem>
                        {subIndex1 === subIndex && (
                          <List sx={{ pl: "20px" }}>
                            {subOption?.innerSubMenu?.map(
                              (subOption2: any, subIndex2: any) => (
                                <ListItem
                                  className={
                                    activeInnerMenu === subOption2.route
                                      ? "active"
                                      : ""
                                  }
                                  key={subIndex2}
                                  sx={{
                                    color:
                                      activeInnerMenu === subOption2.route
                                        ? "#077DD3"
                                        : "#3F3F3F", // Check if activeSubMenu matches route
                                    cursor: "pointer",
                                    background:
                                      activeInnerMenu === subOption2.route
                                        ? "#f9fbff"
                                        : "transparent",
                                    borderLeft:
                                      activeInnerMenu === subOption2.route
                                        ? "4px solid #077DD3"
                                        : "transparent",
                                  }}
                                  onClick={() =>
                                    handleInnerSubMenuClick(subOption2.route)
                                  } // Handle sub menu item click
                                >
                                  {activeInnerMenu === subOption2.route ? (
                                    <ArrowRight />
                                  ) : (
                                    <ArrowRight />
                                  )}{" "}
                                  <ListItemText
                                    primary={subOption2.title}
                                    sx={{
                                      "& .MuiTypography-root": {
                                        fontSize: {
                                          md: "0.8em",
                                          xs: "0.6em",
                                          xl: "0.8em",
                                          sm: "0.6em",
                                          lg: "0.8em",
                                        },
                                      },
                                    }}
                                  />
                                </ListItem>
                              )
                            )}
                          </List>
                        )}
                      </>
                    ))}
                  </List>
                )}
              </React.Fragment>
            ))}
          </List>
        </Drawer>
      ) : (
        <Box
          className={isOpen ? "drawer open" : "drawer closed"}
          role="presentation"
          sx={{
            height: '90vh',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-track': {
              background: '#f1f1f1',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#055FFC',
              borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#555',
            },
          }}
        >
          <List>
            {options.map((option: any, index: any) => (
              <React.Fragment key={index}>
                <ListItem
                  key={option.title}
                  disablePadding
                  sx={{
                    background:
                      activeIndex === index ? "#f9fbff" : "transparent",
                    borderLeft:
                      activeIndex === index
                        ? "4px solid #077DD3"
                        : "transparent",
                  }}
                >
                  <ListItemButton onClick={() => handleItemClick(index)}>
                    <ListItemIcon
                      sx={{
                        filter:
                          activeIndex === index
                            ? "invert(24%) sepia(98%) saturate(6571%) hue-rotate(183deg) brightness(93%) contrast(92%)"
                            : "invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)",
                      }}
                    >
                      {option.icon}
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        filter:
                          activeIndex === index
                            ? "invert(24%) sepia(98%) saturate(6571%) hue-rotate(183deg) brightness(93%) contrast(92%)"
                            : "invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)",
                        "& .MuiTypography-root": {
                          fontSize: {
                            md: "0.8em",
                            xs: "0.6em",
                            xl: "0.8em",
                            sm: "0.6em",
                            lg: "0.8em",
                          },
                        },
                      }}
                    >
                      {option.title}
                    </ListItemText>
                    {activeIndex === index ? (
                      <ArrowRight color="primary" />
                    ) : (
                      ""
                    )}
                  </ListItemButton>
                </ListItem>
                {activeIndex === index && (
                  <List sx={{ pl: "30px" }}>
                    {option.subMenu?.map((subOption: any, subIndex1: any) => (
                      <>
                        <ListItem
                          className={
                            activeSubMenu === subOption.route ? "active" : ""
                          }
                          key={subIndex1}
                          sx={{
                            color:
                              activeSubMenu === subOption.route
                                ? "#077DD3"
                                : "#3F3F3F", // Check if activeSubMenu matches route
                            cursor: "pointer",
                            background:
                              activeSubMenu === subOption.route
                                ? "#f9fbff"
                                : "transparent",
                            borderLeft:
                              activeSubMenu === subOption.route
                                ? "4px solid #077DD3"
                                : "transparent",
                          }}
                          onClick={() =>
                            handleSubMenuClick(subOption.route, subIndex1)
                          } // Handle sub menu item click
                        >
                          {activeSubMenu === subOption.route ? (
                            <ArrowRight />
                          ) : (
                            <ArrowRight />
                          )}{" "}
                          <ListItemText
                            primary={subOption.title}
                            sx={{
                              "& .MuiTypography-root": {
                                fontSize: {
                                  md: "0.8em",
                                  xs: "0.6em",
                                  xl: "0.8em",
                                  sm: "0.6em",
                                  lg: "0.8em",
                                },
                              },
                            }}
                          />
                        </ListItem>
                        {subIndex1 === subIndex && (
                          <List sx={{ pl: "30px" }}>
                            {subOption?.innerSubMenu?.map(
                              (subOption2: any, subIndex2: any) => (
                                <ListItem
                                  className={
                                    activeInnerMenu === subOption2.route
                                      ? "active"
                                      : ""
                                  }
                                  key={subIndex2}
                                  sx={{
                                    color:
                                      activeInnerMenu === subOption2.route
                                        ? "#077DD3"
                                        : "#3F3F3F",
                                    cursor: "pointer",
                                    background:
                                      activeInnerMenu === subOption2.route
                                        ? "#f9fbff"
                                        : "transparent",
                                    borderLeft:
                                      activeInnerMenu === subOption2.route
                                        ? "4px solid #077DD3"
                                        : "transparent",
                                  }}
                                  onClick={() =>
                                    handleInnerSubMenuClick(subOption2.route)
                                  }
                                >
                                  {activeInnerMenu === subOption2.route ? (
                                    <ArrowRight />
                                  ) : (
                                    <ArrowRight />
                                  )}{" "}
                                  <ListItemText
                                    primary={subOption2.title}
                                    sx={{
                                      "& .MuiTypography-root": {
                                        fontSize: {
                                          md: "0.8em",
                                          xs: "0.6em",
                                          xl: "0.8em",
                                          sm: "0.6em",
                                          lg: "0.8em",
                                        },
                                      },
                                    }}
                                  />
                                </ListItem>
                              )
                            )}
                          </List>
                        )}
                      </>
                    ))}
                  </List>
                )}
              </React.Fragment>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default SideBar;
