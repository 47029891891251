import React from 'react';

const AccessConfigIcon = () => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.50065 18.2084C5.10136 17.2307 1.58398 13.08 1.58398 8.70841V3.95841L9.50065 0.791748L17.4173 3.95841V8.70841C17.4173 13.0816 13.8999 17.2307 9.50065 18.2084ZM3.16732 4.75008V8.70841C3.21256 10.5388 3.85635 12.304 5.00017 13.7338C6.14398 15.1636 7.72479 16.1791 9.50065 16.6251C11.2765 16.1791 12.8573 15.1636 14.0011 13.7338C15.1449 12.304 15.7887 10.5388 15.834 8.70841V4.75008L9.50065 2.37508L3.16732 4.75008Z" fill="#077DD3"/>
      <path d="M9.50065 8.70833C10.5937 8.70833 11.4798 7.82223 11.4798 6.72917C11.4798 5.6361 10.5937 4.75 9.50065 4.75C8.40759 4.75 7.52148 5.6361 7.52148 6.72917C7.52148 7.82223 8.40759 8.70833 9.50065 8.70833Z" fill="#077DD3"/>
      <path d="M5.54102 11.875C5.93122 12.5861 6.50336 13.1807 7.19888 13.598C7.89439 14.0153 8.6883 14.2403 9.49935 14.25C10.3104 14.2403 11.1043 14.0153 11.7998 13.598C12.4953 13.1807 13.0675 12.5861 13.4577 11.875C13.4379 10.374 10.8119 9.5 9.49935 9.5C8.17964 9.5 5.56081 10.374 5.54102 11.875Z" fill="#077DD3"/>
    </svg>
  );
}

export default AccessConfigIcon;
