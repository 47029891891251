import React, { FC, useState, useEffect } from 'react';
import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Slider, Typography } from '@mui/material';
import Cropper from 'react-easy-crop';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import getCroppedImgreactang from './CropImagereactangle';
import IosShareIcon from '@mui/icons-material/IosShare';
interface CropDialogProps {
    aspect: number;
    open: boolean;
    onClose: () => void;
    onComplete: (croppedImage: Blob) => void;
    selectedImageType: any
}

const CropDialog: FC<CropDialogProps> = ({ open, onClose, onComplete, aspect, selectedImageType }) => {
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);
    const [preview, setPreview] = useState<string | null>(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [originalImageSize, setOriginalImageSize] = useState<{ width: number; height: number } | null>(null);

    useEffect(() => {
        if (open) {
            setImageSrc(null);
            setCrop({ x: 0, y: 0 });
            setZoom(1);
            setPreview(null);
            setSelectedFile(null);
            setOriginalImageSize(null);
        }
    }, [open]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            setSelectedFile(file);
            const imageUrl = URL.createObjectURL(file);
            setImageSrc(imageUrl);
            setPreview(imageUrl);

            const image = new Image();
            image.src = imageUrl;
            image.onload = () => {
                setOriginalImageSize({ width: image.width, height: image.height });
            };
        }
    };

    const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    const handleSave = async () => {
        if (imageSrc && croppedAreaPixels && originalImageSize) {
            const croppedImage = await getCroppedImgreactang(imageSrc, croppedAreaPixels, originalImageSize);
            onComplete(croppedImage);
            onClose();
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
            <DialogTitle>
                <Typography variant="h6" sx={{ textTransform: "capitalize" }}>Upload {selectedImageType}</Typography>
            </DialogTitle>
            <DialogContent>
                <Box display="flex" flexDirection="column" alignItems="center" gap="6px">
                    {/* <Avatar
                        src={preview || ''}
                        alt="Profile Preview"
                        sx={{
                            width: 100,
                            height: 100,
                            marginBottom: 2,
                        }}
                    /> */}
                    <Box sx={{ display: "flex", flexDirection: "column", color: "rgba(5, 95, 252, 1)", justifyContent: "center", alignItems: "center" }}>
                        <IosShareIcon sx={{ color: "rgba(5, 95, 252, 1)" }} />
                        <Typography sx={{ fontSize: "14px", fontWeight: "600", fontStyle: "italic", textTransform: "capitalize" }}>
                            Upload {selectedImageType} Image
                        </Typography>
                    </Box>
                    {selectedFile && (
                        <Box position="relative" width="100%" height={200}>
                            <Cropper
                                image={preview || ''}
                                crop={crop}
                                zoom={zoom}
                                aspect={aspect}
                                onCropChange={setCrop}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}
                            />
                        </Box>
                    )}
                    <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="profile-pic-upload"
                        type="file"
                        onChange={handleFileChange}
                    />
                    <label htmlFor="profile-pic-upload">
                        <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            startIcon={<PhotoCamera />}
                        >
                            Upload Photo
                        </Button>
                    </label>
                </Box>
            </DialogContent>
            {selectedFile && (
                <DialogContent>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Typography>Zoom</Typography>
                        <Slider
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            onChange={(e, value) => setZoom(value as number)}
                        />
                    </Box>
                </DialogContent>
            )}
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary" disabled={!selectedFile}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CropDialog;
