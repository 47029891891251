import Preloader from '../../components/Preloader';
import { Suspense, lazy } from 'react';
const LoginScreen = lazy(() => import('../../pages/LoginScreen'));
const AuthPaths = [
  {
    path: '/login',
    Content: () => (
      <Suspense fallback={<Preloader/>}>
        <LoginScreen />
      </Suspense>
    )
  }
];

export default AuthPaths;