type Area = {
    x: number;
    y: number;
    width: number;
    height: number;
};

export default async function getCroppedImgreactang(
    imageSrc: string,
    pixelCrop: Area,
    originalImageSize: { width: number; height: number }
): Promise<Blob> {
    const image = new Image();
    image.src = imageSrc;

    await new Promise<void>((resolve) => {
        image.onload = () => resolve();
    });

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
        throw new Error('No 2D context');
    }

    // Set canvas dimensions based on the pixelCrop dimensions
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // Calculate the scaling factor
    const scaleX = image.width / originalImageSize.width;
    const scaleY = image.height / originalImageSize.height;

    // Draw the cropped image onto the canvas
    ctx.drawImage(
        image,
        pixelCrop.x * scaleX, // Adjust based on scaling
        pixelCrop.y * scaleY, // Adjust based on scaling
        pixelCrop.width * scaleX, // Adjust based on scaling
        pixelCrop.height * scaleY, // Adjust based on scaling
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
    );

    return new Promise<Blob>((resolve, reject) => {
        canvas.toBlob((blob) => {
            if (!blob) {
                reject(new Error('Canvas is empty'));
                return;
            }
            resolve(blob);
        }, 'image/jpeg');
    });
}
