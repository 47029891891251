import { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import SideBar from "./SideBar";
import "./styles.css";
import { useMediaQuery } from "@mui/material";

const Layout = ({ children }: { children: any }) => {
  const isSmallScreen = useMediaQuery(" (max-width:1200px)");

  const [openDrawer, setOpenDrawer] = useState(true);
  const closeDrawer = () => {
    setOpenDrawer(false);
  };
  useEffect(() => {
    setOpenDrawer(!isSmallScreen);
  }, [isSmallScreen]);
  const drawerToggler = () => {
    setOpenDrawer(!openDrawer);
  };
  return (
    <>
      <Header drawerToggler={drawerToggler} />
      <div style={{ display: "flex" }}>
        <SideBar isOpen={openDrawer} closeDrawer={closeDrawer} />
        <div className="layout-body">{children}</div>
      </div>
      {/* <Footer /> */}
    </>
  );
};
export default Layout;
