import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

interface FetchTestsParams {
  pageNo: number;
  count: number;
  isPaused: boolean;
}

export const fetchTests = createAsyncThunk(
  'pathology/fetchTests',
  async ({ pageNo, count, isPaused }: FetchTestsParams) => {
    const response = await axios.get(`${process.env.REACT_APP_PATHOLOGY_URL}/test/getAllTests`, {
      params: { pageNo, count, isPaused },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    return response.data;
  }
);

const initialState = {
  tests: [],
  status: 'idle',
  error: null,
  totalCount: 0,
};

const getAdminPathologySlice = createSlice({
  name: 'getAdminPathologySlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTests.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTests.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.tests = action.payload?.data; // Update to correct property name
        state.totalCount = action.payload?.totalCount;
      })
      .addCase(fetchTests.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default getAdminPathologySlice.reducer;
