import React, { useEffect, useState, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import { Box, Button, Switch, TextField, Typography, Snackbar } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

const InstructionEditor = () => {
    const [title, setTitle] = useState('');
    const [editorHtml, setEditorHtml] = useState('');
    const [isUrdu, setIsUrdu] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = location.state || {};
    const quillRef = useRef<ReactQuill>(null);

    // Translations
    const translations = {
        title: isUrdu ? 'عنوان' : 'Title',
        instructions: isUrdu ? 'ہدایات:' : 'Instructions:',
        switchToUrdu: isUrdu ? 'انگریزی میں تبدیل کریں' : 'Switch to Urdu',
        back: isUrdu ? 'پیچھے' : 'Back',
        save: isUrdu ? 'محفوظ کریں' : 'Save',
        update: isUrdu ? 'اپ ڈیٹ کریں' : 'Update',
        placeholder: isUrdu ? 'تفصیلات یہاں درج کریں...' : 'Enter details here...',
    };


    const updateQuillDirectionAndAlignment = () => {
        const quill = quillRef.current?.getEditor();
        if (quill) {
          // Update text direction and alignment of editor content
          quill.format('direction', isUrdu ? 'rtl' : 'ltr');
          quill.format('align', isUrdu ? 'right' : 'left');
      
          // Update styles of editor root element (for visual representation)
          quill.root.style.direction = isUrdu ? 'rtl' : 'ltr';
          quill.root.style.textAlign = isUrdu ? 'right' : 'left';
      
          // Update cursor position when switching from Urdu to English
          if (!isUrdu) {
            quill.setSelection(0, 0); // Move cursor to the beginning of the editor content
          }
        }
      };
        
    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
    };

    const handleEditorChange = (html: string) => {
        setEditorHtml(html);
    };

    useEffect(() => {
        if (id) {
            fetchInstructionById(id);
        }
    }, [id]);

    useEffect(() => {
        updateQuillDirectionAndAlignment(); // Call this whenever isUrdu changes
    }, [isUrdu]);

    const handleLanguageToggle = () => {
        setIsUrdu((prev) => !prev); // Toggle the isUrdu state
    };

    const fetchInstructionById = async (instructionId: string) => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_OPD_API_URL}/set-of-instructions/getOneSetOfInstructionsById/${instructionId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            if (response?.data?.isSuccess) {
                setTitle(response.data.data.setName);
                setEditorHtml(response.data.data.instructions);
            } else {
                setError(response.data.message);
            }
        } catch (error) {
            setError('Error fetching instruction.');
        } finally {
            setLoading(false);
        }
    };

    const handleSave = async () => {
        const requestData = {
            setName: title,
            instructions: editorHtml,
            ...(isUrdu ? { exta: { language: 'ur' } } : {}), // Include the extra object if Urdu
        };

        setLoading(true);
        try {
            const url = id
                ? `${process.env.REACT_APP_OPD_API_URL}/set-of-instructions/updateSetOfInstructionsById/${id}`
                : `${process.env.REACT_APP_OPD_API_URL}/set-of-instructions/addSetOfInstructions`;
            const method = id ? 'patch' : 'post';

            await axios[method](url, requestData, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            setSuccess(id ? translations.update : translations.save);
            setTitle('');
            setEditorHtml('');
            navigate('/set-instructions');
        } catch (error) {
            setError('Error saving data.');
        } finally {
            setLoading(false);
        }
    };

    const toolbarOptions = [
        [{ 'header': [1, 2, 3, false] }],
        ['bold', 'italic', 'underline'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['clean'],
        [{ 'font': [] }, { 'size': ['small', false, 'large', 'huge'] }],
        ['image'], // Add image button to toolbar
        [{ 'direction': 'rtl' }],

    ];

    const modules = {
        toolbar: toolbarOptions,
    };

    return (
        <Box sx={{ background: '#fff', p: 2, maxHeight:'90vh' }}>
            <Typography variant='h6' sx={{ fontSize: '14px', fontWeight: '500', direction: isUrdu ? 'rtl' : 'ltr' }}>
                {translations.title}:
            </Typography>
            <TextField
                type="text"
                size='small'
                value={title}
                onChange={handleTitleChange}
                placeholder={translations.title === 'Title' ? 'Enter title' : 'عنوان درج کریں'}
                className='search-field'
                fullWidth
                sx={{ direction: isUrdu ? 'rtl' : 'ltr' }}
                inputProps={{ lang: isUrdu ? 'ur' : 'en' }}
            />
            <Box mt={1}>
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                    <Typography variant='h6' sx={{ fontSize: '14px', fontWeight: '500', direction: isUrdu ? 'rtl' : 'ltr' }}>
                        {translations.instructions}
                    </Typography>
                    <Box display='flex' alignItems='center' gap={1}>
                        <Typography variant='body2'>{translations.switchToUrdu}:</Typography>
                        <Switch checked={isUrdu} onChange={handleLanguageToggle} />
                    </Box>
                </Box>
                <Box className="editor-container" >
                    <ReactQuill
                        ref={quillRef}
                        value={editorHtml}
                        onChange={handleEditorChange}
                        modules={modules}
                        placeholder={translations.placeholder}
                        // style={{ direction: isUrdu ? 'rtl' : 'ltr' }} // Set text direction based on language
                    />
                </Box>
                <Typography variant='body2' sx={{ mt: 1 }}>
                    {isUrdu ? 'براہ کرم اپنا کی بورڈ اردو میں تبدیل کریں۔' : 'Please switch your keyboard to English.'}
                </Typography>
            </Box>
            <Box display='flex' justifyContent='flex-end' mt={1} alignItems='center' gap={1}>
                <Button variant='outlined' onClick={() => navigate('/set-instructions')}>
                    {translations.back}
                </Button>
                <Button variant='contained' onClick={handleSave} disabled={loading}>
                    {loading ? 'Loading...' : (id ? translations.update : translations.save)}
                </Button>
            </Box>
            {/* <Snackbar
                open={!!error}
                autoHideDuration={6000}
                onClose={() => setError('')}
                message={error}
            />
            <Snackbar
                open={!!success}
                autoHideDuration={6000}
                onClose={() => setSuccess('')}
                message={success}
            /> */}
        </Box>
    );
};

export default InstructionEditor;
