import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { fetchProfileData } from './profileSlice';
import { useDispatch } from 'react-redux';


const setProfileData = (profileData) => ({
    type: 'profile/setProfileData',
    payload: profileData,
});
// const dispatch = useDispatch()
export const fetchAllDoctorsData = createAsyncThunk('fetchDoctorsData', async (_, { getState, dispatch }) => {
    try {

       
        // console.log('ddd', profile);
        // console.log('docSlice profileData', profileData);
        const token = localStorage.getItem('token');
        // const userCategory = profileData.userCategory;
        // console.log('userCategory', userCategory);
        const headers = {
            Authorization: `Bearer ${token}`
        };
        let url = '';
        //  if(localStorage.getItem('userType').includes('user'))
        //  if(profile?.data?.userType.includes('user'))
        //  {
        //   if(profile?.data?.userCategory === 'Receptionist')
        //   {
        //     url = `${process.env.REACT_APP_BASE_URL}/users/getAssignedDoctores`;
        //   }
        //  }else{
        const count = 500000
        url = `${process.env.REACT_APP_BASE_URL}/users/getAssignedDoctores?count=${count}`;
        //  }
        const res = await axios.get(
            url,
            {
                headers
            }
        );

        // console.log(res.data, ' response data');
        return res.data;
    } catch (error) {
        console.log(error, ' error in userArticle');
    }
});

const allDoctorSlice = createSlice({
    name: 'allDoctorSlice',
    initialState: {
        isLoading: false,
        isSuccess: false,
        error: null,
        // profileData: null,
        allDoctors: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllDoctorsData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchAllDoctorsData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                // console.log(action.payload, 'API response'); // Log the entire response
                // state.allDoctors = action.payload;
                state.allDoctors = action.payload?.data;
            })
            .addCase(fetchAllDoctorsData.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.error = action.payload;
            })
            // .addCase(fetchAllDoctorsData.fulfilled, (state, action) => {
            //     state.profileData = action.payload?.data;
            // });
    }
});

export default allDoctorSlice.reducer;
export const { resetFetchArticleListState } = allDoctorSlice.actions;




